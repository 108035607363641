import React from 'react';
import RegisterNewCompanyUserForm from 'components/Form/RegisterNewCompanyUserForm';

const RegisterByInvitationPage = () => {
	return (
		<section className="section section--register_invitation">
			<div className="section__in">
				<RegisterNewCompanyUserForm limitContainerMod />
			</div>
		</section>
	);
};

export default RegisterByInvitationPage;
