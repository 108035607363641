import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import jsonAppData from 'utils/jsonAppData';
import { useLocation } from 'react-router-dom';

const WorkflowNavigation = () => {
	const { t } = useTranslation('workflowNavigation');
	const location = useLocation();

	const pageNavClasses = classNames('workflow_navigation__list page_nav offset_mod');

	return (
		<div className="workflow_navigation">
			<ul className={pageNavClasses}>
				{jsonAppData.workflowNavigation.map(({
					label,
					url,
				}, index) => {
					let isActive;

					if (label === 'requestOrOrder' && (location.pathname === '/summary' || location.pathname === '/get-my-glass')) {
						isActive = true;
					} else {
						if (url === location.pathname) {
							isActive = true;
						}
					}

					const isActiveClassName = isActive ? 'workflow_navigation__link--active_state' : '';

					return (
						<li className="workflow_navigation__item" key={index}>
							<div className={`workflow_navigation__link ${isActiveClassName}`}>
								{t(label)}
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default WorkflowNavigation;
