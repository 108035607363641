import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import gsap from 'gsap';
import { AppDataContext } from 'context/appData';
import { ConfigurationDataContext } from 'context/configuration';
import { UiContext } from 'context/ui';
import { MainMenuLangList } from 'components/MainMenu';
import { PageNavHeading } from 'components/PageNavHeading';
import LogoImage from 'i/logo.svg';
import { ReactComponent as EditIcon } from 'i/icons/edit.svg';
import { MENU_CLOSED_STATE, MENU_OPEN_STATE, TABLET_MEDIA_POINT } from 'utils/constant';
import APP_DATA from 'utils/jsonAppData';
import HeaderInfoList from './HeaderInfoList';
import MenuTrigger from './MenuTrigger';

const Header = ({ darkMode }) => {
	const { langApp, themeSettings } = useContext(AppDataContext);
	const { configuration, resetConfiguration } = useContext(ConfigurationDataContext);
	const {
		isMainMenuOpen, mainMenuState, showPopupByKey, windowWidth,
	} = useContext(UiContext);
	const location = useLocation();
	const { t } = useTranslation('resetConfigurationForm');
	const navigate = useNavigate();

	const $menuTriggerWOpeningEl = useRef();
	const $menuTriggerWClosingEl = useRef();
	const $menuTriggerOpeningEl = useRef();
	const $currentLangEl = useRef();

	const showResetConfigurationPopup = (e) => {
		if (!configuration) {
			navigate('/');
		} else {
			e.preventDefault();
			e.stopPropagation();
			showPopupByKey('genericWarning', {
				title: t('title'),
				message: (
					<div>
						<p style={{ fontSize: 'larger' }}>{t('confirmReset')}</p>
						<p>
							<br />
							<span style={{ marginRight: '1em' }}>
								<EditIcon className="icon" />
							</span>
							{t('backToConfigurator')}
						</p>
					</div>
				),
				showAbortButton: true,
				dangerConfirmButton: true,
				confirmDialog: isConfirmed => isConfirmed && resetConfiguration(),
			});
		}
	};

	const onHeaderLogoClick = (e) => {
		if (location.pathname === '/cart' || location.pathname === '/summary' || location.pathname === '/get-my-glass') {
			showResetConfigurationPopup(e);
		} else {
			navigate('/');
		}
	};

	const openingMainMenuAnim = () => {
		gsap.fromTo([$menuTriggerWOpeningEl.current, $menuTriggerWClosingEl.current], {
			opacity: gsap.utils.wrap([1, 0]),
		}, {
			opacity: gsap.utils.wrap([0, 1]),
			immediateRender: false,
			duration: 0,
		});
	};

	const closingMainMenuAnim = () => {
		const tlAnim = gsap.timeline({
			delay: 0.5,
		});

		tlAnim
			.set($menuTriggerWOpeningEl.current, {
				opacity: 1,
				zIndex: 10,
			})
			.fromTo([$menuTriggerWOpeningEl.current, $menuTriggerOpeningEl.current], {
				x: gsap.utils.wrap(['20rem', '-20rem']),
				y: gsap.utils.wrap(['-20rem', '20rem']),
			}, {
				x: 0,
				y: 0,
				duration: 0.8,
			}, 'anim')
			.to($menuTriggerWClosingEl.current, {
				opacity: 0,
				duration: 0,
			});

		if ($currentLangEl.current) {
			tlAnim
				.fromTo($currentLangEl.current, {
					x: '-20rem',
					y: '20rem',
				}, {
					y: 0,
					x: 0,
					duration: 0.8,
				}, 'anim');
		}
	};

	useEffect(() => {
		if (isMainMenuOpen && mainMenuState === MENU_CLOSED_STATE) {
			openingMainMenuAnim();
		}

		if (!isMainMenuOpen && mainMenuState === MENU_OPEN_STATE) {
			closingMainMenuAnim();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMainMenuOpen]);

	const renderMenuTrigger = () => {
		if (themeSettings && themeSettings.general_settings && themeSettings.general_settings.menu_trigger_button) {
			const buttonTitles = themeSettings.general_settings.menu_trigger_button;
			const buttonOpenTitle = buttonTitles.open || APP_DATA.header.menuTriggerButtonTitle[langApp];
			const buttonCloseTitle = buttonTitles.close || APP_DATA.header.menuTriggerButtonCloseTitle[langApp];

			return (
				<>
					<div className="menu_trigger_w open_mod" ref={$menuTriggerWOpeningEl}>
						<MenuTrigger
							title={buttonOpenTitle[langApp]}
							ref={$menuTriggerOpeningEl}
						/>
						<span className="lang_current_title" ref={$currentLangEl}>{langApp}</span>
					</div>
					<div className="menu_trigger_w close_mod" ref={$menuTriggerWClosingEl}>
						<MenuTrigger title={buttonCloseTitle[langApp]} />
					</div>
				</>
			);
		}

		return null;
	};

	return (
		<header className={classNames("header js-global-element", {
			"header--dark_mod": darkMode,
		})}
		>
			<div className="header_in">
				<button type="button" className="header_logo_btn" onClick={onHeaderLogoClick}>
					{
						themeSettings
							&& themeSettings.general_settings
							&& themeSettings.general_settings.site_logo
							? (
								<img
									className="header_logo_img"
									src={themeSettings.general_settings.site_logo.url}
									alt={themeSettings.general_settings.site_logo.alt}
								/>
							) : (
								<img
									className="header_logo_img"
									src={LogoImage}
									alt="CDR Logo"
								/>
							)
					}
				</button>
				{renderMenuTrigger()}
				<HeaderInfoList />
				{
					isMainMenuOpen
						? null
						: <PageNavHeading />
				}
				{windowWidth >= TABLET_MEDIA_POINT ? (
					<MainMenuLangList />
				) : null}
			</div>
		</header>
	);
};

export default Header;
