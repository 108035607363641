import React, { useContext, useRef, useState } from 'react';
import { BlockHeading } from 'components/BlockHeading';
import { useTranslation } from 'react-i18next';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {
	emailValidation,
	validatePassword,
} from 'utils';
import { Formik } from 'formik';
import { PROMISE_STATES } from 'utils/constant';
import { ButtonSubmit } from 'components/Buttons';
import { firebaseAuth } from 'utils/firebase';
import ToastifyHandler from 'utils/ToastifyHandler';
import { UiContext } from 'context/ui';
import jsonAppData from 'utils/jsonAppData';
import FormField from './FormField';

const LoginForm = ({
	formInPopup,
	toggleLogged,
}) => {
	const { t } = useTranslation(['loginForm', 'firebaseErrorMessages']);
	const formikRef = useRef(null);
	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const { showPopupByKey } = useContext(UiContext);
	const notificationHandler = useRef(new ToastifyHandler());

	const validate = {
		userLoginEmail: email => emailValidation(email),
		userLoginPassword: password => validatePassword(t('baseFormPlaceholders:userPasswordPlaceholder'), password),
	};

	const initialValues = {
		userLoginEmail: '',
		userLoginPassword: '',
	};

	return (
		<Formik
			initialValues={initialValues}
			innerRef={formikRef}
			mapPropsToValues={() => {
				return {
					...initialValues,
				};
			}}
			validate={values => Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error }),
				};
			}, {})}
			onSubmit={async (values) => {
				const { userLoginEmail, userLoginPassword } = values;

				try {
					setLoadingStatus(PROMISE_STATES.pending);
					notificationHandler.current.pending(t('loggingInProgress'));

					await signInWithEmailAndPassword(firebaseAuth, userLoginEmail, userLoginPassword);

					setLoadingStatus(PROMISE_STATES.fulfilled);

					if (formInPopup) {
						showPopupByKey();
					}

					notificationHandler.current.success(t('successfullyLoggedIn'));
				} catch (error) {
					const { code } = error;
					notificationHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
					setLoadingStatus(PROMISE_STATES.rejected);
				}
			}}
			validateOnChange={false}
		>
			{({
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
			}) => (
				<form className="login_form" onSubmit={handleSubmit}>
					{!formInPopup && (
						<BlockHeading
							title={t('title')}
						/>
					)}
					<div className="login_form__in">
						{formInPopup && (
							<BlockHeading
								title={t('title')}
							/>
						)}
						<ul className="form_fields offset_2_mod">
							{jsonAppData.loginForm.map(({
								keyForI18n,
								id,
								type,
								iconName,
							}) => {
								return (
									<li className="form_fields__item" key={id}>
										<FormField
											id={id}
											placeholder={t(`${keyForI18n}`)}
											name={id}
											type={type}
											required
											iconName={iconName}
											values={values}
											errors={errors}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched}
										/>
										<div className="form_fields__error">{touched[id] && errors[id]}</div>
									</li>
								);
							})}
						</ul>
						<div className="login_form__button">
							<ButtonSubmit
								buttonText={t('loginButton')}
								isDisabled={loadingStatus === PROMISE_STATES.pending}
							/>
						</div>
						<div className="login_form__note">
							{t('dontHaveAccount')}
							&nbsp;
							<a
								href="#"
								onClick={(e) => {
									e.stopPropagation();
									if (formInPopup) {
										showPopupByKey('registration');
									} else {
										toggleLogged();
									}
								}}
							>
								{t('signUp')}
							</a>
						</div>
						<div className="login_form__note">
							<button
								onClick={(e) => {
									e.stopPropagation();
									showPopupByKey('lostPassword');
								}}
								type="button"
								className="login_form__note_button"
							>
								{t('lostPassword')}
							</button>
						</div>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default LoginForm;
