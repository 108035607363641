export const DEFAULT_GLASS_SIZES = {
	quantity: '-',
	m2: '-',
	linearMeter: '-',
	kg: '-',
	price: '-',
};

export const replaceZerosWithDash = (values) => {
	return Object.fromEntries(
		Object.entries(values).map(([key, value]) => [key, value === 0 ? '-' : value]),
	);
};

export const roundPriceToFiveCents = (price) => Math.round(price * 20) / 20;

const calculateRoundedPcSurface = (width, height) => {
	const surfaceInSquareMeters = (width * height) / 1000000;
	// CDR: Round to two decimals prior to multiplication with
	// quantity to match their calculation software: Round(x * 100) / 100
	return Math.round(surfaceInSquareMeters * 100) / 100;
};

const findItemByName = (row, name) => row.find(item => item.name === name) || { value: null };

export const checkIsFieldError = (fields, id, name) => {
	return fields.some(item => item.id === id && item.fields.some(field => field.name === name));
};

export const calculateSubTotalSizes = (positions, flatGlassStructure, showPrice, minimalGlassSurface, modelGlassFactor) => {
	const validRows = positions.filter(({ row }) => {
		return Array.isArray(row) && row.length > 0;
	});

	const emptyOrZeroFields = validRows.map(({ row, inactive, id }) => {
		const fields = row.filter(({ name, value }) => {
			const intValue = parseInt(value, 10);
			return !inactive && (name === "length" || name === "width" || name === "quantity") && (intValue === 0 || isNaN(intValue));
		});

		return {
			id,
			fields,
		};
	});

	const values = validRows
		.map(({ row, inactive }, index) => {
			if (inactive) return null;

			const { value: width } = findItemByName(row, "width");
			const { value: height } = findItemByName(row, "length");
			const { value: quantity } = findItemByName(row, "quantity");
			const { value: modelGlassChecked } = findItemByName(row, "form");
			const modelGlassPriceFactor = modelGlassChecked === 'checked' ? modelGlassFactor : 1.0;
			const numericQuantity = parseFloat(quantity);

			if (width && height && quantity && numericQuantity >= 1) {
				const parsedWidth = parseFloat(width);
				const parsedHeight = parseFloat(height);
				const m2Pc = calculateRoundedPcSurface(parsedWidth, parsedHeight);
				const kgPc = m2Pc * flatGlassStructure.sqmWeight;
				const m2Pos = m2Pc * numericQuantity;
				const roundedMinM2Pc = Math.max(minimalGlassSurface, m2Pc);
				const roundedPcPrice = roundPriceToFiveCents(roundedMinM2Pc * flatGlassStructure.sqmPrice * modelGlassPriceFactor);
				const roundedPosPrice = roundedPcPrice * numericQuantity;
				// eslint-disable-next-line camelcase
				const linearMeter_mm = 2 * (parsedWidth + parsedHeight);
				// eslint-disable-next-line camelcase
				const linearMeter = (Math.ceil(linearMeter_mm / 10.0) / 100.0); // meters rounded up to 2 digits

				return {
					index,
					quantity: numericQuantity,
					m2Pc,
					m2: m2Pos,
					linearMeter,
					linearMeterPos: linearMeter * numericQuantity,
					kg: kgPc,
					kgPos: kgPc * numericQuantity,
					price: showPrice && flatGlassStructure.sqmPrice !== null ? roundedPosPrice : null,
				};
			}

			return null;
		});

	const validActiveRowLength = validRows.filter(({ inactive }) => {
		return !inactive;
	}).length;

	const sizes = values.filter(item => item !== null);

	const isFilled = validActiveRowLength === sizes.length;

	return { sizes, isFilled, emptyOrZeroFields };
};

export const calculateTotalSizes = (values, showPrice) => {
	return values.reduce((acc, innerValues) => {
		innerValues.forEach(({
			quantity, m2, linearMeterPos, kgPos, price,
		}) => {
			acc.quantity += quantity;
			acc.m2 += m2;
			acc.linearMeter += linearMeterPos;
			acc.kg += kgPos;
			acc.price = showPrice && acc.price !== null && price !== null ? acc.price + price : null;
		});
		return acc;
	}, {
		quantity: 0, m2: 0, linearMeter: 0, kg: 0, price: 0,
	});
};

export const getGlassSizes = (glassTypes, showPrice, configuratorData) => {
	const values = [];
	let emptyOrZeroFields = [];
	let isSizesValid = true;

	const minimalGlassSurface = configuratorData.pricing.min_pos_sqm;
	const modelGlassFactor = configuratorData.pricing.model_glass_factor;

	glassTypes.map(({ positions, flatGlassStructure }) => {
		const { sizes, isFilled, emptyOrZeroFields: fields } = calculateSubTotalSizes(positions, flatGlassStructure, showPrice, minimalGlassSurface, modelGlassFactor);

		emptyOrZeroFields = emptyOrZeroFields.concat(fields);
		values.push(sizes);

		isSizesValid = isSizesValid && isFilled;
		return null;
	});

	const valuesSum = replaceZerosWithDash(calculateTotalSizes(values, showPrice));

	return {
		values, valuesSum, isSizesValid, emptyOrZeroFields,
	};
};
