import Insulation from "./Glass/InsulationValues";
import Papa from 'papaparse';

export default class GlassDBLoader {
  static loadDb(glassDb, glass_csvs, onLoaded) {
    try {
      const tr = JSON.parse(glass_csvs.glass_translation_json);
      glassDb.addTranslation(tr);
    } catch (e) {
      console.error('Error fetching glass translations:', e);
    }

    const csvsToLoadPromises = []
    const csv_map = {
      'frg': { prop: 'frg_csv', header: true },
      'out': { prop: 'out_csv', header: true },
      'mid': { prop: 'mid_csv', header: true },
      'price': { prop: 'prices_csv', header: false },
    };
    Object.keys(csv_map).forEach(k => {
      const v = csv_map[k];
      let csvLoaded, csvLoadError;

      csvsToLoadPromises.push(new Promise((resolve, reject) => {
        csvLoaded = resolve;
        csvLoadError = reject;
      }));

      Papa.parse(glass_csvs[v.prop], {
        header: v.header,
        skipEmptyLines: 'greedy',
        step: function (row) {
          if (row.errors.length) {
            // don't warn on empty lines
            if (Object.keys(row.data).length > 1) {
              console.warn(`Error parsing csv ${k}`);
              console.warn(row.errors);
            }
          } else {
            try {
              glassDb.addRow(k, row.data);
            } catch (e) {
              console.error(`Error parsing ${v.url} at ${row.data.ID ? `row ${row.data.ID}` : `cursor ${row.meta.cursor}`}: ${e}`);
              console.error(row.data);
              csvLoadError();
            }
          }
        },
        complete: csvLoaded,
      });
    });
    glassDb.db['uValue']['2-glazed'] = Insulation.uValueTable['2-glazed'];
    glassDb.db['uValue']['3-glazed'] = Insulation.uValueTable['3-glazed'].filter(v => v.spacer1 === v.spacer2); // only load values with equal spacers

    Promise.allSettled(csvsToLoadPromises).then(() => onLoaded());
  }

  static linkDatasheets(glassDb) {
    const mapGlass = (glassLabel, ds) => {
      const glass = glassDb.db[glassLabel].find(o => o.id === ds.glass_id);
      if (glass) {
        glass.datasheets = ds;
      }
    }
    const dsDb = glassDb.db['datasheets'];
    dsDb['frg'].map(ds => mapGlass('frg', ds));
    dsDb['mid'].map(ds => mapGlass('mid', ds));
    dsDb['out'].map(ds => mapGlass('out', ds));
    dsDb.loaded = true;
  }
}
