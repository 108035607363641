import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockHeading } from 'components/BlockHeading';
import usePopup from './usePopup';

const GenericWarningPopup = ({
	isOpen,
}) => {
	const { closePopup, popupData, popupFrame } = usePopup({
		isOpen,
		extraClassName: "generic_warning",
		onPopupExit: () => popupData.confirmDialog && popupData.confirmDialog(false),
	});
	const { t } = useTranslation('genericWarningPopup');
	const $abortButton = useRef(null);
	const $confirmButton = useRef(null);

	const handleConfirm = (success = true) => {
		closePopup();
		if (popupData.confirmDialog) {
			popupData.confirmDialog(success);
		}
	};

	useEffect(() => {
		if (isOpen) {
			if (!popupData.showAbortButton) {
				if ($confirmButton.current) {
					$confirmButton.current.focus();
				}
			} else {
				if ($abortButton.current) {
					$abortButton.current.focus();
				}
			}
		}
	}, [isOpen, $confirmButton]);

	return popupFrame(
		<div className="generic_warning__in">
			<BlockHeading
				title={popupData.title || t('warning')}
				iconName={popupData.icon || (popupData.confirmDialog ? "question" : "warning")}
				offsetMod
			/>
			<div className="generic_warning__text popup_note">{popupData.message}</div>
			<div className="generic_warning__buttons">
				{
					popupData.confirmDialog && (
						<div className="generic_warning__button">
							<button
								className={`btn_base${popupData.dangerConfirmButton ? ` generic_warning__danger` : ''}`}
								type="button"
								onClick={handleConfirm}
								ref={$confirmButton}
							>
								{t('confirm')}
							</button>
						</div>
					)
				}
				{
					popupData.showAbortButton && (
						<div className="generic_warning__button">
							<button
								className="btn_base"
								type="button"
								onClick={() => handleConfirm(false)}
								ref={$abortButton}
							>
								{t('abort')}
							</button>
						</div>
					)
				}
			</div>
		</div>,
	);
};

export default GenericWarningPopup;
