const userInfo = ({
	guestUser, currentUser, companyInfo,
}) => {
	if (currentUser) {
		let companyProps = {};
		if (companyInfo) {
			const {
				name: companyName, VATNumber: companyVATNumber, email: companyEmail, tel: companyPhone, zip: companyZip, city: companyCity, address: companyStreet,
			} = companyInfo;
			companyProps = {
				companyName,
				companyVATNumber,
				companyStreet,
				companyZip,
				companyCity,
				companyPhone,
				companyEmail,
			};
		}
		const { displayName: userDisplayName, email: userEmail } = currentUser;
		return {
			...companyProps,
			userDisplayName,
			userEmail,
		};
	}
	if (guestUser) {
		const {
			companyName,
			userName,
			email: userEmail,
			companyVATNumber,
			companyStreet,
			companyZIP: companyZip,
			companyCity,
			companyPhone,
		} = guestUser;
		return {
			companyName,
			companyVATNumber,
			companyStreet,
			companyZip,
			companyCity,
			companyPhone,
			userDisplayName: userName,
			userEmail,
		};
	}
	return null;
};

export default userInfo;
