import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import 'styles/_loader.scss';
import { UiContext } from 'context/ui';
import { AppDataContext } from 'context/appData';

const GlassProductCanvas = () => {
	const { isConfiguratorLoaded } = useContext(AppDataContext);
	const { viewApiRef } = useContext(UiContext);
	const location = useLocation();
	const [isEnabled, setIsEnabled] = useState(location.pathname === '/');

	useEffect(() => {
		const isRoot = location.pathname === '/';
		setIsEnabled(isRoot);
		if (!isRoot) {
			// stop rendering immediately rather than when state update took place
			viewApiRef.current?.stop();
		}
	}, [location, viewApiRef]);

	useEffect(() => {
		if (!isConfiguratorLoaded || !isEnabled) return;
		viewApiRef.current.start();
	}, [isConfiguratorLoaded, isEnabled, viewApiRef]);

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			viewApiRef.current?.stop();
		};
	}, [viewApiRef]);

	return (
		<div className={classNames("glass_product_col img_mod", {
			"glass_product_col--enabled_mod": isEnabled,
		})}
		>
			<div className="loader">
				<div className="loader__progress-bar">
					<div className="loader__progress-bar-fill" />
				</div>
			</div>
			<canvas className="glass_product_img home__canvas webgl-canvas" />
		</div>
	);
};

export default GlassProductCanvas;
