import React, {
	useEffect,
	useContext,
	useRef,
} from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import 'styles/blocks/configurator_page.scss';
import 'styles/plugins/_slick.scss';

import {
	GlassProductSidebar,
	GlassProductCanvas,
	GlassProductSelector,
	GlassProductSliderControl,
} from 'components/GlassProduct';
import { TABLET_MEDIA_POINT } from 'utils/constant';

import { Api } from 'components/ThreeJs/assets/index.module';
import { Filters } from 'components/ThreeJs/assets/filter/main';
import { GlassStructureContext } from 'context/configuration';
import { UiContext } from 'context/ui';
import { useLocation } from 'react-router';
import { getParameterByName } from 'utils';
import { useTranslation } from 'react-i18next';
import DisclaimerBox from 'components/GlassProduct/DisclaimerBox';
import WarningUIElement from 'components/ThreeJs/assets/filter/elementsUI/WarningUIElement';
import { AppDataContext } from 'context/appData';

const HomePage = ({ key }) => {
	const location = useLocation();
	const sectionClassNames = classNames('section full_mod section--home_page_mod', {
		'section--home_page_hidden_state': location.pathname !== '/',
	});
	// eslint-disable-next-line object-curly-newline
	const { showPopupByKey, windowWidth, viewApiRef } = useContext(UiContext);
	const { langApp, configuratorData, setConfiguratorLoaded } = useContext(AppDataContext);
	const {
		setFlatGlassStructure, uiHandler, setUiHandler, uiHandlerRef,
	} = useContext(GlassStructureContext);
	const windowLoaded = useRef(false);
	const bottomKeys = [
		'manufacturer',
		'fireResistanceClass',
		'structure',
		'insulation',
		'application',
		'whiteGlass',
		'resistanceClass',
		'buttJoints',
		'sound',
	];
	const rightKeys = [
		'fireResistant',
		'spacer1',
		'gas1',
		'middleGlass',
		'outsideGlass',
	];

	const sliderSettings = {
		dots: false,
		infinite: false,
		variableWidth: true,
		arrows: true,
		draggable: false,
		swipe: false,
		nextArrow: <GlassProductSliderControl type="next" />,
		prevArrow: <GlassProductSliderControl type="prev" />,
	};

	function useCustomTranslation(namespace) {
		const { t, i18n } = useTranslation(namespace);
		return { t, i18n };
	}

	const { t: tVisualizerComponent, i18n } = useCustomTranslation('visualizerComponent');
	const { t: tGlassCharacteristics } = useCustomTranslation('glassCharacteristics');

	useEffect(() => {
		// If UI is already initialized, set the user's language preference, otherwise UI init will take care of it
		if (!langApp || !uiHandlerRef.current) return;
		uiHandlerRef.current.i18n.setLanguage(langApp);
	}, [langApp, uiHandlerRef]);

	useEffect(() => {
		setTimeout(() => {
			if (!windowLoaded.current) {
				viewApiRef.current = Api;
				viewApiRef.current.load({
					initial_cam_pos: {
						zoom: 8, orientation: 120, tilt: 10, position: { x: 1.0, y: 1.0, z: -1.0 },
					},
					dpr: window.devicePixelRatio,
					invert_mouse: true,
					lang: {
						MeasurementArrows_label_format: tVisualizerComponent('measurementArrowsLabelFormat'),
					},
				});

				windowLoaded.current = true;

				window.filters_handler = new Filters({ translation: tGlassCharacteristics, i18n }, setFlatGlassStructure);
				uiHandlerRef.current = window.filters_handler.ui;
				uiHandlerRef.current.subscribeConfiguratorUILoaded(() => {
					setConfiguratorLoaded(true);
					// Sometimes the loader bar is not removed properly by OHZI module. Do it here.
					const loaderProgressBar = document.getElementsByClassName('loader');
					if (loaderProgressBar.length > 0) {
						loaderProgressBar[0].classList.add('hidden');
					}
				});
				setUiHandler(uiHandlerRef.current);

				// Get the action to complete.
				const mode = getParameterByName('mode');
				if (mode === 'resetPassword') {
					showPopupByKey('resetPassword');
				}
			}
		}, 1000);
	}, [i18n, setFlatGlassStructure, showPopupByKey, tGlassCharacteristics, tVisualizerComponent,
		setConfiguratorLoaded, setUiHandler, uiHandlerRef, viewApiRef]);

	useEffect(() => {
		if (!uiHandler || !configuratorData) return;
		if (configuratorData.glassdb && configuratorData.prices_csv) {
			uiHandler.setGlassDB(configuratorData.glassdb, configuratorData.prices_csv);
		}
		if (configuratorData.datasheets) {
			uiHandler.setDatasheets(configuratorData.datasheets);
		}
		if (configuratorData.sound_dampening) {
			let soundDampeningTable = null;
			let genericFRGs = null;
			try {
				soundDampeningTable = JSON.parse(configuratorData.sound_dampening.sound_dampening_table);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(`Failed to parse sound_dampening JSON string: ${e}`);
			}
			try {
				genericFRGs = JSON.parse(configuratorData.sound_dampening.generic_frgs);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(`Failed to parse sound_dampening JSON string: ${e}`);
			}
			if (soundDampeningTable) {
				uiHandler.setSoundValues(soundDampeningTable, genericFRGs);
			}
		}
	}, [uiHandler, configuratorData]);

	return (
		<section id={key} className={sectionClassNames}>
			<div className="section__in">
				<div className="glass_product">
					<div className="glass_product__row">
						<GlassProductCanvas />
						<GlassProductSidebar rightKeys={rightKeys} />
					</div>
				</div>
			</div>
			<div className="glass_product_bottom">
				<div className="glass_product_category">
					{windowWidth >= TABLET_MEDIA_POINT ? (
						<div>
							<div className="instruction instruction_bottom">
								1.&nbsp;
								{tGlassCharacteristics('commonConfigurationAction:firstSelectProperties')}
							</div>
							<div className="glass_product_category_list glass_product_category_list--style_mod">
								{bottomKeys.map(f => (
									<GlassProductSelector key={f} name={f} uiHandlerRef={uiHandlerRef} wrapperClass="glass_product_category_item_v2" labelClass="glass_product_category_label_v2" />
								))}
							</div>
						</div>
					) : (
						<Slider className="glass_product_category_list" {...sliderSettings}>
							<div className="instruction instruction_bottom">
								{tGlassCharacteristics(
									"commonConfigurationAction:firstSelectProperties",
								)}
							</div>
							{bottomKeys.map((f) => (<GlassProductSelector key={f} name={f} uiHandlerRef={uiHandlerRef} />))}
						</Slider>
					)}
				</div>
				<WarningUIElement uiHandlerRef={uiHandlerRef} />
				<DisclaimerBox />
			</div>
		</section>
	);
};

export default HomePage;
