import React, {
	useContext,
	useEffect,
	useState,
	useRef,
} from 'react';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';

import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { BlockHeading } from 'components/BlockHeading';
import { UiContext } from 'context/ui';
import { Formik } from 'formik';
import { firebaseAuth } from 'utils/firebase';
import {
	clearAllBrowserUrlParameter,
	getParameterByName,
	validateConfirmPassword,
	validatePassword,
} from 'utils';
import ToastifyHandler from 'utils/ToastifyHandler';
import { ButtonSubmit } from 'components/Buttons';
import { Spinner } from 'components/Spinner';
import { PROMISE_STATES } from 'utils/constant';
import FormField from './FormField';

const VERIFIED_PASSWORD_RESET_CODE_STATE = 'verified';

const ResetPasswordForm = () => {
	const { t } = useTranslation('resetPasswordForm');
	const { showPopupByKey } = useContext(UiContext);
	const [resetCodeState, setResetCodeState] = useState(PROMISE_STATES.pending);
	const [errorMsg, setErrorMsg] = useState(null);
	const [firebaseActionCode, setFirebaseActionCode] = useState(null);
	const formikRef = useRef();
	const notificationsHandler = useRef(new ToastifyHandler());
	const [confirmPasswordResetLoading, setConfirmPasswordResetLoading] = useState(PROMISE_STATES.default);

	const validate = {
		newPassword: password => validatePassword(t('baseFormPlaceholders:newPasswordPlaceholder'), password),
		repeatNewPassword: repeatPasswordValue => validateConfirmPassword(formikRef.current.values.newPassword, repeatPasswordValue),
	};

	const initialValues = {
		newPassword: '',
		repeatNewPassword: '',
	};

	const closePopup = () => {
		showPopupByKey();
	};

	useEffect(() => {
		const handleAsyncEffect = async () => {
			const actionCode = getParameterByName('oobCode');

			try {
				await verifyPasswordResetCode(firebaseAuth, actionCode);

				setResetCodeState(VERIFIED_PASSWORD_RESET_CODE_STATE);
				setFirebaseActionCode(actionCode);
			} catch (error) {
				setResetCodeState(error.code);
				setErrorMsg(t(error.code, { ns: 'firebaseErrorMessages' }));
			}
		};

		handleAsyncEffect();
	}, [t]);

	return (
		<Formik
			initialValues={initialValues}
			innerRef={formikRef}
			mapPropsToValues={() => {
				return {
					...initialValues,
				};
			}}
			validate={values => Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error }),
				};
			}, {})}
			onSubmit={async (values) => {
				const { newPassword } = values;

				setConfirmPasswordResetLoading(PROMISE_STATES.pending);
				notificationsHandler.current.pending(t('notifications:passwordResetInProgress'));

				try {
					await confirmPasswordReset(firebaseAuth, firebaseActionCode, newPassword);

					setConfirmPasswordResetLoading(PROMISE_STATES.fulfilled);
					notificationsHandler.current.success(t('notifications:passwordHasBeenSuccessfullyChanged'));

					clearAllBrowserUrlParameter();

					showPopupByKey('login');
				} catch (error) {
					const { code } = error;
					notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
					setConfirmPasswordResetLoading(PROMISE_STATES.rejected);
				}
			}}
			validateOnChange={false}
		>
			{({
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
			}) => (
				<form className="lost_password_form" onSubmit={handleSubmit} ref={formikRef}>
					<button className="popup_close" type="button" onClick={closePopup}>
						<CloseIcon className="icon icon-close size_mod" />
					</button>
					<div className="lost_password_form__in">
						<BlockHeading title={t('title')} />
						{resetCodeState === VERIFIED_PASSWORD_RESET_CODE_STATE ? (
							<>
								<div className="form_fields">
									<div className="form_fields__item">
										<FormField
											id="newPassword"
											placeholder={t('baseFormPlaceholders:newPasswordPlaceholder')}
											name="newPassword"
											type="password"
											iconName="lock"
											values={values}
											required
											handleBlur={handleBlur}
											handleChange={handleChange}
										/>
										<div className="form_fields__error">{touched.newPassword && errors.newPassword}</div>
									</div>
									<div className="form_fields__item">
										<FormField
											id="repeatNewPassword"
											placeholder={t('baseFormPlaceholders:repeatNewPasswordPlaceholder')}
											name="repeatNewPassword"
											type="password"
											iconName="lock"
											values={values}
											required
											handleBlur={handleBlur}
											handleChange={handleChange}
										/>
										<div className="form_fields__error">{touched.repeatNewPassword && errors.repeatNewPassword}</div>
									</div>
								</div>
								<ButtonSubmit
									buttonText={t('submitButton')}
									isDisabled={confirmPasswordResetLoading === PROMISE_STATES.pending}
								/>
							</>
						) : (
							resetCodeState === PROMISE_STATES.pending ? (
								<Spinner darkTheme />
							) : (
								<div className="lost_password_form__note">
									<p>{errorMsg}</p>
									<button
										onClick={(e) => {
											e.stopPropagation();
											clearAllBrowserUrlParameter();
											showPopupByKey('lostPassword');
										}}
										type="button"
										className="login_form__note_button"
									>
										{t('loginForm:lostPassword')}
									</button>
								</div>
							)
						)}
					</div>
				</form>
			)}
		</Formik>
	);
};

export default ResetPasswordForm;
